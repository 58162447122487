import React from 'react';
import { Link } from 'gatsby';
//hooks
import useHeaderContact from 'hooks/useHeaderContact';
// styles
import clsx from 'clsx';
import styles from './header.module.css';
// other
import Identificators from 'components/googleAnalyticsIdentificators';

const Routes = require('../../../components/routes').Routes;

interface IContact {
  containerStyles?: string;
  buttonStyles?: string;
  calendlyLinkStyles?: string;
  linkId?: string;
  linkText?: string;
}

const Contacts = ({
  containerStyles,
  buttonStyles,
  calendlyLinkStyles,
  linkId = Identificators.CONTACT_US_HEADER,
  linkText = 'Contact us',
}: IContact) => {
  const contact = useHeaderContact();
  return (
    <div className={clsx(styles.btnGroup, containerStyles)}>
      <Link
        id={linkId}
        className={clsx('btn btn_60 btn__white', styles.btn, styles.contact, buttonStyles)}
        to={Routes.GET_IN_TOUCH}
      >
        {linkText}
      </Link>
      <a
        className={clsx('btnCall', calendlyLinkStyles)}
        // href={`${contact.calendly_link}?hide_gdpr_banner=1`}
        href={`${contact.calendly_link}?utm_source=brocoders&utm_medium=header&utm_campaign=calendly_link&hide_gdpr_banner=1`}
      >
        {contact.link_name}
      </a>
    </div>
  );
};
export default Contacts;
